





































































































import ViewModel from '@/models/ViewModel'
import ReconciliationModule from '@/store/model/ReconciliationModule'
import { Component, Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import IconAction from '../../../components/IconAction/IconAction.vue'

@Component({
  components: {
    IconAction,
  },
})
export default class HeaderInfo extends ViewModel {
  public reconciliationModule = getModule(ReconciliationModule)

  @Prop({ default: () => {} })
  public selected_item!: any

  get row() {
    return this.reconciliationModule.parent_details
  }

  public avatarName(name: string) {
    if (!name) return ''
    let splited = name.split('')

    return splited[0].slice(0, 1) + splited[1].slice(0, 1)
  }

  public toggleRowInfo(cond: any) {
    this.layout.toggleRowInfo(cond)
  }
}
