import SelectOption from '@/models/interface/SelectOption'

export const query_settings = {
  company_fields: [
    {
      name: 'not_agency',
      key: 'not_agency_id',
      type: 'agency',
      description: 'Exclude specified agency',
    },
    {
      name: 'agency',
      key: 'agency_id',
      type: 'agency',
      description: 'Include only the specified agency',
    },

    {
      name: 'not_station',
      key: 'not_station_id',
      type: 'station',
      description: 'Exclude specified station',
    },
    {
      name: 'station',
      key: 'station_id',
      type: 'station',
      description: 'Include only the specified station',
    },

    {
      name: 'not_advertiser',
      key: 'not_advertiser_id',
      type: 'advertiser',
      description: 'Exclude specified advertiser',
    },
    {
      name: 'advertiser',
      key: 'advertiser_id',
      type: 'advertiser',
      description: 'Include only the specified advertiser',
    },
  ],
  user_fields: [
    {
      name: 'sr',
      key: 'sales_rep_id',
      description: 'Include only the specified sales rep',
    },
    {
      name: 'am',
      key: 'account_manager_id',
      description: 'Include only the specified account manager',
    },
  ],
  media_plan_fields: [
    {
      name: 'not_media_plan',
      key: 'media_plan_id',
      description: 'Exclude specified media plan',
    },
    {
      name: 'media_plan',
      key: 'media_plan_id',
      description: 'Include only the specified media plan',
    },
  ],
  custom_fields: [
    {
      name: 'source:first_party',
      value: 'billing_source:first_party',
      description: 'Includes only records with First Party as billing source',
    },
    {
      name: 'source:third_party',
      value: 'billing_source:third_party',
      description: 'Includes only records with Third Party as billing source',
    },
    {
      name: 'mode:actuals',
      value: 'billing_mode:actuals',
      description: 'Includes only records with Actuals as billing mode',
    },
    {
      name: 'mode:booked',
      value: 'billing_mode:booked',
      description: 'Includes only records with Booked as billing mode',
    },
    {
      name: 'mode:booked_cap',
      value: 'billing_mode:booked_cap',
      description: 'Includes only records with Booked Cap as billing mode',
    },
    {
      name: 'calendar:monthly',
      value: 'billing_calendar:monthly',
      description: 'Includes only records with Monthly as billing calendar',
    },
    {
      name: 'calendar:broadcast',
      value: 'billing_calendar:broadcast',
      description: 'Includes only records with Broadcast as billing calendar',
    },
    {
      name: 'status:pending',
      value: 'status:pending',
      description: 'Includes only records with Pending as status',
    },
    {
      name: 'status:approved',
      value: 'status:approved',
      description: 'Includes only records with Approved as status',
    },
  ],
}

export const publisher_query_settings = {
  company_fields: [],
  media_plan_fields: [],
  custom_fields: [
    {
      name: 'source:first_party',
      value: 'billing_source:first_party',
      description: 'Includes only records with First Party as billing source',
    },
    {
      name: 'source:third_party',
      value: 'billing_source:third_party',
      description: 'Includes only records with Third Party as billing source',
    },
    {
      name: 'status:pending',
      value: 'status:pending',
      description: 'Includes only records with Pending as status',
    },
    {
      name: 'status:approved',
      value: 'status:approved',
      description: 'Includes only records with Approved as status',
    },
  ],
}

export const billing_calendar = [
  new SelectOption('Monthly', 'monthly'),
  new SelectOption('Broadcast', 'broadcast'),
  new SelectOption('Full Campaign', 'full_campaign'),
]

export const billing_source = [
  new SelectOption('1st Party', 'first_party'),
  new SelectOption('3rd Party', 'third_party'),
]

export const billing_source_parent = [
  ...billing_source,
  {
    name: 'Mixed',
    value: 'mixed',
    $isDisabled: true,
  },
]

export const billing_mode = [
  new SelectOption('Actuals - Open Ended', 'actuals'),
  new SelectOption('Booked', 'booked'),
  // new SelectOption('Booked + Exceeding', 'booked_plus'),
  new SelectOption('Booked Cap', 'booked_cap'),
]

export const billing_mode_parent = [
  ...billing_mode,
  {
    name: 'Mixed',
    value: 'mixed',
    $isDisabled: true,
  },
]

export const BatchMode = [
  new SelectOption('Visible Items', 'visible'),
  new SelectOption('Selected Items', 'selected'),
  new SelectOption('All Pending Items (Based on Filter)', 'filter'),
]
