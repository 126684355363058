







































































import IconAction from '@/components/IconAction/IconAction.vue'
import ReconciliationGroup from '@/models/ReconciliationGroup'
import WebMessage from '@/models/WebMessage'
import ReconciliationModule from '@/store/model/ReconciliationModule'
import Component from 'vue-class-component'
import { Prop, Vue, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({
  components: {
    IconAction,
  },
})
export default class NavigationFooter extends Vue {
  public reconciliationModule = getModule(ReconciliationModule)

  @Prop({ default: () => [] })
  public table_data!: ReconciliationGroup[]

  @Prop({ required: true })
  public active_navigation_id!: string

  @Prop({ required: true })
  public current_page!: number

  @Prop({ required: true })
  public total_records!: number

  @Prop({ required: true })
  public per_page!: number | string

  @Prop({ required: true })
  public loading!: boolean

  @Prop({ required: true })
  public active_navigation_group_id!: string

  public navigation_enabled = false

  public skip_approved = true

  public group_index: number = -1

  public item_index: number = -1

  public max_page(): number {
    return this.per_page === 'all' ? 1 : Math.ceil(this.total_records / Number(this.per_page))
  }

  private get active_group_id() {
    return this.active_navigation_group_id
  }

  private set active_group_id(value: string) {
    this.$emit('update:active_navigation_group_id', value)
  }

  private get active_id() {
    return this.active_navigation_id
  }

  private set active_id(value: string) {
    this.$emit('update:active_navigation_id', value)
  }

  /**
   * When table refreshs, wait for the loading to finish and set the active index to the first avilable item
   */
  @Watch('loading')
  private onLoadingChange() {
    if (this.navigation_enabled && this.loading) {
      this.resetNavigation()

      this.waitForLoading(() => {
        this.navigation_enabled = true
        this.moveToNext()
      })
    }
  }

  public mounted() {
    this.reconciliationModule.toggle_smart_nav(this.navigation_enabled)
    document.addEventListener('keydown', this.onKeyDown)
  }

  /**
   * Unregister event listeners
   */
  public beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyDown)
  }

  private onKeyDown(e: KeyboardEvent) {
    if (this.loading) return

    if (!this.navigation_enabled) {
      if (e.shiftKey && e.key === 'ArrowDown') {
        e.preventDefault()
        this.init()
      }
      return
    }
    if (e.shiftKey && e.key === 'ArrowDown') {
      e.preventDefault()
      this.moveToNext(false)
      return
    }

    if (e.shiftKey && e.key === 'ArrowRight') {
      e.preventDefault()
      this.moveToNext(true)
      return
    }

    if (e.shiftKey && e.key === 'ArrowUp') {
      e.preventDefault()
      this.moveToPrevious()
      return
    }

    if (e.shiftKey && e.code === 'Space') {
      e.preventDefault()
      this.skip_approved = !this.skip_approved
    }
  }

  private init() {
    // Activate Smart Navigation Header
    this.reconciliationModule.toggle_smart_nav(true)
    this.navigation_enabled = true
    this.moveToNext()
  }

  private waitForLoading(callback: any) {
    if (this.loading) {
      setTimeout(() => {
        this.waitForLoading(callback)
      }, 100)
    } else {
      // Callback should be delayed to allow the page to complete the rendering
      setTimeout(() => {
        callback()
      }, 500)
    }
  }

  public resetNavigation() {
    this.group_index = -1
    this.item_index = -1
  }

  private isValidItem(): boolean {
    return (
      this.table_data[this.group_index]
      && this.table_data[this.group_index].items[this.item_index]
      && (!this.skip_approved
        || this.table_data[this.group_index].items[this.item_index].status === 'pending')
    )
  }

  private identifyValidItem(direction: string): boolean {
    let valid_item_index = false
    do {
      if (direction === 'next') this.item_index++
      else this.item_index--

      // Check if item exists
      if (
        this.group_index < 0
        || this.group_index >= this.table_data.length
        || this.item_index >= this.table_data[this.group_index].items.length
        || this.item_index < 0
      ) {
        if (direction === 'next') {
          this.group_index++
          this.item_index = 0
        } else {
          this.group_index--
          this.item_index = this.table_data[this.group_index]
            ? this.table_data[this.group_index].items.length - 1
            : 0
        }
      }

      // Check if group exists
      if (this.group_index >= this.table_data.length || this.group_index < 0) {
        break
      }

      valid_item_index = this.isValidItem()
    } while (!valid_item_index)

    return valid_item_index
  }

  private moveToNext(approve: boolean = false) {
    if (
      approve
      && this.table_data[this.group_index]
      && this.table_data[this.group_index].items[this.item_index]
    ) {
      this.table_data[this.group_index].items[this.item_index].status = 'approved'
    }

    this.moveTo('next')
  }

  private moveToPrevious() {
    this.moveTo('previous')
  }

  private moveTo(direction: string) {
    let result = this.identifyValidItem(direction)
    if (!result) {
      if (direction === 'next') {
        if (this.current_page < this.max_page()) {
          this.$emit('next-page')
          // Wait for page to load and target first item
          return
        }
        WebMessage.error('You have reached the end of the list')
        return
      }

      // Move to previous page
      if (this.current_page > 1) {
        this.$emit('prev-page')
        // Wait for page to load and target first item
        return
      }
      WebMessage.error('You have reached the beginning of the list')
      return
    }

    // Hide Previous item
    if (
      this.active_group_id !== ''
      && this.active_group_id !== this.table_data[this.group_index].uuid
    ) {
      this.$emit('toggleTable', {
        target: this.active_group_id,
        action: 'hide',
      })
    }

    this.active_group_id = this.table_data[this.group_index].uuid
    this.active_id = this.table_data[this.group_index].items[this.item_index].id
    // Update Smart Navigation Header Information
    this.reconciliationModule.parentDetails(this.table_data[this.group_index])

    // Show Sub Items
    this.$emit('toggleTable', {
      target: this.table_data[this.group_index].uuid,
      action: 'show',
    })

    this.focusOnItem()
  }

  private focusOnItem() {
    try {
      let row: any = null
      let input: any = null
      setTimeout(() => {
        row = document.getElementById(`row-${this.active_id}`)
        input = document.getElementById(`impressions-${this.active_id}`)
          ?? document.getElementById(`billing-source-${this.active_id}`)
        if (row) {
          row!.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
            inline: 'center',
          })

          setTimeout(() => {
            if (input) input.focus()
          }, 200)
        }
      }, 200)
    } catch (error) {
      // console.error('NavigationFooter@navigateToItem::error', error)
    }
  }
}
