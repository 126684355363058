






























import {
  Vue, Component, Prop, Ref, Watch,
} from 'vue-property-decorator'
import FormInput from '@/components/FormInput/FormInput.vue'
import Inventory from '@/models/Inventory'
// @ts-ignore
import LiquorTree from 'liquor-tree'

Vue.use(LiquorTree)

@Component({
  components: {
    FormInput,
  },
})
export default class AdunitPicker extends Vue {
  @Ref() readonly treeview: any

  @Prop({ default: false })
  public vertical!: boolean

  @Prop({ default: () => [], required: true })
  public value!: string[]

  @Prop({ default: '' })
  public label!: string

  @Prop({ default: '' })
  public rules!: [Object, String]

  public adunit_filter: string = ''

  public adunits = []

  public busy = true

  public tree_options = {
    checkbox: true,
    checkOnSelect: false,
    autoCheckChildren: false,
  }

  public get formLabel() {
    return this.label + (this.rules.includes('required') ? ' *' : '')
  }

  public get local_value(): string[] {
    return this.value
  }

  public set local_value(val: string[]) {
    this.$emit('input', val)
  }

  public updateAdUnits() {
    this.local_value = this.treeview.checked().map((x: any) => x.id)

    /* if (this.model.adunit_ids.length > 0) {
      let base = 100 / this.model.adunit_ids.length
      let distribution = []
      for (let i = 0; i < this.model.adunit_ids.length; i++) {
        distribution.push(base * (i + 1))
      }
      this.adunits_distribution = distribution
    } */
  }

  @Watch('value')
  private onValueUpdate() {
    if (this.adunits.length > 0) {
      this.syncChecked()
    }
  }

  private syncChecked() {
    this.treeview.findAll((n: any) => this.value.includes(n.id)).check()
  }

  public created() {
    Inventory.module.getTree().then(tree => {
      this.adunits = tree
      this.busy = false
      setTimeout(() => {
        this.syncChecked()
      }, 500)
    })
  }
}
