









































import { Component, Vue, Prop } from 'vue-property-decorator'
// @ts-ignore
import CKEditor from '@ckeditor/ckeditor5-vue2'
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { uniqueId } from 'lodash'

@Component({
  components: {
    ckeditor: CKEditor.component,
  },
})
export default class RichEditor extends Vue {
  private editor = ClassicEditor

  @Prop({ default: 'input' })
  public id!: string

  @Prop({ default: true })
  public edit!: boolean

  @Prop({ default: false })
  public noValidation!: any

  @Prop({ default: false })
  public noErrors!: any

  @Prop({ default: '' })
  public rules!: [Object, String]

  @Prop({ default: '' })
  public label!: string

  @Prop({ default: '' })
  public description!: string

  @Prop({ default: '' })
  public value!: string

  @Prop({ default: false })
  public vertical!: boolean

  @Prop({ default: '' })
  public name!: string

  public get uuid() {
    return `${this.id}-${uniqueId()}`
  }

  public get formLabel() {
    return this.label ? this.label + (this.rules.includes('required') ? ' *' : '') : ''
  }

  public get local_value(): string {
    return this.value
  }

  public set local_value(val: string) {
    this.$emit('input', val)
  }
}
