




















































































import { Component, Prop, Watch } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import Widget from '@/components/Widget/Widget.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import { integerMask, currencyMask } from '@/models/interface/Masks'
import MediaPackagePicker from '@/components/MediaPackagePicker/MediaPackagePicker.vue'
import Reconciliation from '@/models/Reconciliation'
import { billing_source, billing_mode, BatchMode } from '../options'

@Component({
  components: {
    Widget,
    FormInput,
    SelectPicker,
    DatePicker,
    MediaPackagePicker,
  },
})
export default class BatchEditReconciliation extends ViewModel {
  @Prop({ required: true })
  public show!: boolean

  @Prop({ required: true })
  public scope!: string

  @Prop({ default: false })
  public has_selected!: boolean

  public mode: string = 'visible'

  private local_scope: string = ''

  private batch_item: Reconciliation = new Reconciliation()

  public selected: string[] = []

  public get options() {
    return {
      billing_source,
      billing_mode,
      modes: this.has_selected ? BatchMode : BatchMode.filter(mode => mode.value !== 'selected'),
    }
  }

  private get local_show() {
    return this.show
  }

  private set local_show(value: boolean) {
    this.$emit('update:show', value)
  }

  @Watch('show')
  private onShowChange(value: string) {
    this.mode = this.has_selected ? 'selected' : 'visible'
  }

  @Watch('scope')
  private onTypeChange(value: string) {
    this.local_scope = value
  }

  private confirm() {
    this.$emit('confirm', { selected: this.selected, values: this.batch_item, mode: this.mode })
  }

  public cancel() {
    this.$emit('cancel')
  }

  public isEnabled(name: string) {
    return this.selected.includes(name)
  }

  public created() {
    this.local_scope = this.scope
    this.mode = this.has_selected ? 'selected' : 'visible'
  }
}
