<template>
  <header class="page-header d-print-none">
    <div class="container">
      <slot name="content" />
    </div>
  </header>
</template>

<script>
export default {}
</script>

<style>
.page-header {
  z-index: 10;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding: 3px 0 3px 0;
  line-height: 60px;

  box-shadow: 5px 5px 10px rgb(0 0 0 / 20%) !important;

  background-color: rgb(247, 247, 247);
}

@media (min-width: 768px) {
  .footer {
    margin: 0 -40px 0 -40px;
  }
  .page-header {
    margin: -40px -40px 0 -40px;
  }
}

@media (max-width: 768px) {
  .page-header {
    margin: -20px -15px;
  }
}
</style>
