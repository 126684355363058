import { startCase } from 'lodash'
import { Md5 } from 'ts-md5/dist/md5'
import Api from './Api'
import WebMessage from './WebMessage'

export default class Reconciliation {
  public id: string = ''

  public impressions: number = 0

  public reconciliated_impressions: number = 0

  public billing_month: string = ''

  public billing_source: string = ''

  public billing_calendar: string = ''

  public billing_mode: string = ''

  public revenue_rate: number = 0

  public cost_rate: number = 0

  public margin: number = 0

  public status: string = ''

  public line_item_id: string = ''

  public code: string = ''

  public name: string = ''

  public number: number = 0

  public start_at: string = ''

  public end_at: string = ''

  public booked_impressions: number = 0

  public booked_revenue: number = 0

  public booked_month_impressions: number = 0

  public booked_month_revenue: number = 0

  public lifetime_impressions: number = 0

  public inventory_id: string = ''

  public inventory_name: string = ''

  public dynamic_cost_rate_id: null | string = null

  public type: string = ''

  public metadata: any = {}

  // Computed properties
  public get billable_impressions(): number {
    if (this.billing_mode === 'booked') {
      return this.booked_impressions
    }

    let impressions = 0

    if (this.billing_source === 'first_party') {
      impressions = this.impressions
    } else if (this.billing_source === 'third_party') {
      impressions = this.reconciliated_impressions
    }

    if (this.billing_mode === 'booked_plus') {
      return impressions > this.booked_impressions ? impressions : this.booked_impressions
    }
    if (this.billing_mode === 'booked_cap') {
      return impressions > this.booked_impressions ? this.booked_impressions : impressions
    }

    return impressions
  }

  public get revenue_total(): number {
    return this.revenue_rate * (this.billable_impressions / 1000)
  }

  public get cost_total(): number {
    if (this.type === 'client') {
      return this.cost_rate * (this.impressions / 1000)
    }
    return (
      this.cost_rate
      * ((this.billing_source === 'first_party' ? this.impressions : this.reconciliated_impressions)
        / 1000)
    )
  }

  public get impressions_discrepancy() {
    if (this.billing_source === 'first_party') return '-'
    return (this.reconciliated_impressions - this.impressions) / this.impressions
  }

  public get revenue_discrepancy() {
    return (this.booked_revenue - this.revenue_total) / this.booked_revenue
  }

  public get billing_source_name(): string {
    return startCase(this.billing_source.replaceAll('_', ' '))
  }

  public get billing_mode_name(): string {
    return startCase(this.billing_mode.replaceAll('_', ' '))
  }

  public get status_name(): string {
    return startCase(this.status.replaceAll('_', ' '))
  }

  public get uuid():string {
    return this.id
  }

  // Check if object has changed
  public get is_dirty(): boolean {
    return this._hash !== this.calculateChecksum()
  }

  private _hash: string | Int32Array = ''

  private calculateChecksum(): string | Int32Array {
    return Md5.hashStr(
      JSON.stringify({
        cost_rate: this.cost_rate,
        reconciliated_impressions: this.reconciliated_impressions,
        billing_calendar: this.billing_calendar,
        billing_mode: this.billing_mode,
        billing_source: this.billing_source,
        dynamic_cost_rate_id: this.dynamic_cost_rate_id ?? null,
        status: this.status,
      }),
    )
  }

  public updateChecksum() {
    this._hash = this.calculateChecksum()
  }

  public static toObject(source: any): Reconciliation {
    let instance = new Reconciliation()

    // Remove computed properties
    delete source.revenue_total
    delete source.cost_total

    Object.assign(instance, source)

    // Create item checksum
    instance.updateChecksum()

    return instance
  }

  public toObject(source: any): Reconciliation {
    return Reconciliation.toObject(source)
  }

  public static toObjectList(source: any): Reconciliation[] {
    return source.map((item: any) => Reconciliation.toObject(item))
  }

  public toObjectList(source: any): Reconciliation[] {
    return Reconciliation.toObjectList(source)
  }

  public static save(type: string, rows: Array<Reconciliation>) {
    const api = new Api()

    const dirty_items = rows.filter(i => i.is_dirty)

    if (dirty_items.length === 0) {
      return Promise.resolve(true).then(Reconciliation.onSave)
    }

    return api
      .post(
        `reconciliations/${type}`,
        rows
          .filter(i => i.is_dirty)
          .map(i => ({
            id: i.id,
            status: i.status,
            reconciliated_impressions: i.reconciliated_impressions,
            billing_source: i.billing_source,
            cost_rate: i.cost_rate,
            billing_mode: i.billing_mode,
            billing_calendar: i.billing_calendar,
            dynamic_cost_rate_id: i.dynamic_cost_rate_id ?? null,
          })),
      )
      .then((response: any) => {
        rows.forEach((item: Reconciliation) => {
          item.updateChecksum()
        })
        Reconciliation.onSave(response)
      })
      .catch(Reconciliation.onError)
  }

  public save(type: string) {
    return Reconciliation.save(type, [this])
  }

  protected static onSave(response: any) {
    WebMessage.success('Reconciliation saved!')

    return response
  }

  private static onError(error: any) {
    return error
  }
}
