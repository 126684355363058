import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'

export default [

  {
    key: 'checkbox',
    label: '',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    type: 'checkbox',

  },
  {
    key: 'number',
    label: '#',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'agency_name',
    label: 'Agency',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
  },
  {
    key: 'station_name',
    label: 'Station',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
  },
  {
    key: 'advertiser_name',
    label: 'Advertiser',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
  },
  {
    key: 'name',
    label: 'Media Plan',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'start_at',
    label: 'Flight Dates',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'billing_calendar',
    label: 'Billing Callendar',
    sortable: true,
    show: true,
    // thStyle: { width: '100%' },
    class: 'text-center align-middle',
  },
  {
    key: 'billing_source',
    label: 'Billing Source',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'billing_mode',
    label: 'Billing Mode',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'booked_impressions',
    label: 'Booked Impressions',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'month_impressions',
    label: 'Month Impressions',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'items_reconciliated_impressions',
    label: '3rd Party Impressions',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'impressions_discrepancy',
    label: 'Discrepancy',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'revenue_rate',
    label: 'Rate',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'revenue_total',
    label: 'Revenue Total',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'cost_rate',
    label: 'Cost Rate',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'cost_total',
    label: 'Cost Total',
    sortable: true,
    show: false,
    class: 'text-center align-middle',
  },
  {
    key: 'status',
    label: 'Status',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'action',
    label: 'Action',
    sortable: false,
    show: true,
    type: 'actions',
    class: 'text-center align-middle',
  },
]
