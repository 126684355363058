





































































































































































































import {
  Component, Prop, Vue, Ref,
} from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import { uniqueId } from 'lodash'
import DataTableField from './DataTableField'

/**
 * DataTableComponent
 *
 * Creates a datatable with customizable redering types and column filters.
 * The Component allows passing trough templates to the Bootstrap table
 *
 */
@Component({
  components: {
    Widget,
    IconAction,
    SelectPicker,
  },
})
export default class DataTable extends Vue {
  // Component unique ID
  public uuid: string = uniqueId()

  // Bootstrap table reference
  @Ref()
  public readonly dataTable!: any

  // Reference DIV for loading dynamic components
  @Ref()
  public readonly container!: any

  /**
   *  Array of objects containing list of fields
   *
   * @type Array<DataTableFiled>
   */
  @Prop({ default: () => [] })
  public readonly fields!: Array<DataTableField>

  // Default sorting method
  @Prop({ default: () => null })
  public readonly sortBy!: string | null

  // Checked Items (Sync)
  @Prop({ default: () => [] })
  public readonly checked!: Array<string>

  // Pormise that return table records
  @Prop({ default: () => [] })
  public readonly rows!: Promise<any>

  // Total amount of records
  @Prop({ default: () => 0 })
  public readonly records!: number

  // Loading indicator
  @Prop({ default: () => false })
  public readonly loading!: boolean

  // Table search query
  @Prop({ default: () => false })
  public readonly query!: any

  // Field Filters
  @Prop({ default: () => false })
  public fieldFilters!: any

  // Filter timeout tracker
  public filterTimeout: any = null

  // Check if all rows are selected
  public get allSelected(): boolean {
    return this.dataTable && this.dataTable.localItems.length > 0
      ? this.checked.length === this.dataTable.localItems.length
      : false
  }

  // Check or uncheck all records
  public set allSelected(checked: boolean) {
    this.local_checked = checked
      ? this.dataTable.localItems.map((r: any) => r.id)
      : []
  }

  // Check if datatable has checked records but not all
  public get indeterminate(): boolean {
    return (
      this.checked.length > 0
      && this.dataTable
      && this.checked.length < this.dataTable.localItems.length
    )
  }

  // Return active field filters
  public get localFieldFilters() {
    return this.fieldFilters
  }

  // Upate parent field filters
  public set localFieldFilters(value) {
    this.$emit('update:fieldFilters', value)
  }

  // Default table pagination options
  public pagination_options: any = [
    { name: '10', value: '10' },
    { name: '25', value: '25' },
    { name: '50', value: '50' },
    { name: '100', value: '100' },
  ]

  // Returns checked prop to prevent updating prop
  public get local_checked(): Array<string> {
    return this.checked
  }

  // Update parent checked prop
  public set local_checked(value: Array<string>) {
    this.$emit('update:checked', value)
  }

  // Sort by column
  public sort_by: string = 'created_at'

  // Inital page size
  public page_size: number = 25

  // Inital page
  public page: number = 1

  // Sort DESC or ASC
  public sort_desc: boolean = true

  // Refresh datatable
  public refresh() {
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout)
    }
    this.dataTable.refresh()
  }

  // Clear all selection
  public resetSelection() {
    this.local_checked = []
    this.allSelected = false
  }

  // Toggle checkbox selection
  public toggleSelectAll(value: boolean) {
    this.allSelected = value
  }

  /**
   * Runs on datatable action click.
   *
   * If action has callable, execute callable and if it returns a promise, refresh datatable on resolve.
   * If no callable, emit event name
   */
  public triggerAction(event: string, item: any) {
    // @ts-ignore
    let action = this.fields
      .find((f: any) => f.key === 'action')
      .actions.find((a: any) => a.event === event)

    if (action && action.action !== undefined && action.action !== null) {
      let p = action.action(item, this.dataTable, this.container)

      if (p instanceof Promise) {
        p.then((refresh: boolean) => {
          if (refresh) {
            this.refresh()
          }
        })
      }
    }
    this.$emit(event, item)
  }

  /**
   * Refresh data on filter change, input change (Disabled due to negative feedback)
   */
  public onFilterChange() {
    /*
    // If already has a timeout, clear timeout
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout)
      this.filterTimeout = null
    }

    // Create timeout ot refresh datatable once user stops typing
    this.filterTimeout = setTimeout(() => {
      this.refresh()
    }, 2000)
    */
  }

  /**
   * Runs on compnent mount
   */
  public mounted() {
    // If default sort is set, copy to local var
    if (this.sortBy) {
      this.sort_by = this.sortBy
    }
  }
}
