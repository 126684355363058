import BigNumber from 'bignumber.js'
import { startCase } from 'lodash'
import Api from './Api'
import PaginateOptions from './interface/PaginateOptions'
import Reconciliation from './Reconciliation'

export default class ReconciliationGroup {
  public media_plan_id: null | string = null

  public number: null | string = null

  public name: string = ''

  public agency_name: null | string = null

  public station_name: null | string = null

  public advertiser_name: null | string = null

  public sales_rep_id: null | string = null

  public sales_rep_name: null | string = null

  public account_manager_id: null | string = null

  public account_manager_name: null | string = null

  public start_at: string = ''

  public end_at: string = ''

  public booked_impressions: number = 0

  public impressions: number = 0

  public booked_month_impressions: number = 0

  public month_impressions: number = 0

  public publisher: string = ''

  public top_inventory_id: string = ''

  public top_inventory_name: string = ''

  public items: Array<Reconciliation> = []

  public booked_revenue: number = 0

  public booked_month_revenue: number = 0

  public lifetime_impressions: number = 0

  // Start items loaded
  public _showDetails = true

  public _showItems = false

  // Dynamic Properties
  // Parse property values and returns global value
  private getPropertyFromItems(property: string): string {
    let values: any = []
    this.items.filter(item => {
      let i = values.findIndex(
        (r: string) => property in item && r === item[property as keyof typeof item],
      )
      if (i <= -1 && property in item) {
        values.push(item[property as keyof typeof item])
      }
      return null
    })

    return values.length === 1 ? values[0] : 'mixed'
  }

  // Apply values to all items
  private setPropertyFromItems(property: string, value: string) {
    if (value === 'mixed' || !value) return
    this.items.forEach(item => {
      if (property in item && (property === 'status' || item.status === 'pending')) {
        // @ts-ignore
        item[property as keyof typeof item] = value
      }
    })
  }

  public get status(): string {
    return this.getPropertyFromItems('status')
  }

  public set status(status: string) {
    this.setPropertyFromItems('status', status)
  }

  public get dynamic_rate_cost_id(): string {
    return this.getPropertyFromItems('dynamic_rate_cost_id')
  }

  public set dynamic_rate_cost_id(dynamic_rate_cost_id: string) {
    this.setPropertyFromItems('status', dynamic_rate_cost_id)
  }

  public set reconciliated_impressions(value: any) {
    this.setPropertyFromItems('reconciliated_impressions', value)
  }

  public set cost_rate(value: string) {
    this.setPropertyFromItems('cost_rate', value)
  }

  public get cost_rate() {
    // @ts-ignore
    return this.cost_total / (this.items_impressions / 1000)
  }

  public get reconciliated_impressions() {
    return this.getPropertyFromItems('reconciliated_impressions')
  }

  public get revenue_rate() {
    return this.revenue_total / this.items_impressions / 1000
  }

  public get billing_calendar(): string {
    return this.getPropertyFromItems('billing_calendar')
  }

  public set billing_calendar(value: string) {
    this.setPropertyFromItems('billing_calendar', value)
  }

  public get billing_source(): string {
    return this.getPropertyFromItems('billing_source')
  }

  public set billing_source(value: string) {
    this.setPropertyFromItems('billing_source', value)
  }

  public get billing_mode(): string {
    return this.getPropertyFromItems('billing_mode')
  }

  public set billing_mode(value: string) {
    this.setPropertyFromItems('billing_mode', value)
  }

  public get items_reconciliated_impressions(): number | string {
    return this.items.reduce(
      (acc, item) =>
        acc
        + Number(
          item.billing_source === 'first_party' ? item.impressions : item.reconciliated_impressions,
        ),
      0,
    )
  }

  public get billing_calendar_name(): string {
    return startCase(this.billing_calendar.replaceAll('_', ' '))
  }

  public get billing_source_name(): string {
    return startCase(this.billing_source.replaceAll('_', ' '))
  }

  public get billing_mode_name(): string {
    return startCase(this.billing_mode.replaceAll('_', ' '))
  }

  public set items_reconciliated_impressions(value: number | string) {
    if (!value) value = 0
    if (typeof value === 'string') value = parseInt(value.replace(/,/g, ''))

    const available_impressions: number = value
      - this.items
        .filter(item => item.status !== 'pending')
        .reduce((acc, item) => acc + Number(item.reconciliated_impressions), 0)

    const elegible_items = this.items.filter(item => item.status === 'pending')
    const total_impressions = elegible_items.reduce((acc, item) => acc + item.impressions, 0)
    let impressions_count = 0
    elegible_items.forEach(item => {
      const b_impressions = new BigNumber(item.impressions)
      const impressions = +b_impressions
        .dividedBy(total_impressions)
        .times(value)
        .integerValue()
      if (impressions_count + impressions > available_impressions) {
        item.reconciliated_impressions = available_impressions - impressions_count
        impressions_count += available_impressions - impressions_count
      } else {
        item.reconciliated_impressions = impressions
        impressions_count += impressions
      }
    })
  }

  // Computed Properties

  public get uuid(): string {
    return this.number ?? this.publisher.replace(' ', '_').toLocaleLowerCase()
  }

  public get is_dirty(): boolean {
    return this.items.some(item => item.is_dirty)
  }

  public get revenue_total(): number {
    return this.items.reduce((acc, item) => acc + item.revenue_total, 0)
  }

  public get cost_total(): number {
    return this.items.reduce((acc, item) => acc + item.cost_total, 0)
  }

  public get items_impressions(): number {
    return this.items.reduce((acc, item) => acc + item.impressions, 0)
  }

  public get impressions_discrepancy() {
    if (this.billing_source === 'first_party') return '-'
    return (
      (Number(this.items_reconciliated_impressions) - this.items_impressions)
      / this.items_impressions
    )
  }

  public get items_booked_revenue(): number {
    return this.items.reduce((acc, item) => acc + item.booked_revenue, 0)
  }

  public get items_rate(): number {
    return (
      this.items.reduce((acc, item) => acc + item.revenue_rate * item.impressions, 0)
      / this.items_impressions
    )
  }

  public get revenue_discrepancy() {
    return (this.items_booked_revenue - this.revenue_total) / this.items_booked_revenue
  }

  public get status_name(): string {
    return startCase(this.status.replaceAll('_', ' '))
  }

  public get has_third_party_billing() {
    return this.items.some(item => item.billing_source === 'third_party')
  }

  /**
 * Sample:
 * ReconciliationGroup.paginate(
      {
        page_size: 25,
        page: 1,
        order_by: 'period',
        order: 'desc',
        query: [],
      },
      'client',
      '202206',
    ).then(r => console.log(r))
 */

  public static async paginate(options: PaginateOptions, type: string, month: string) {
    const api = new Api(false)
    return api
      .get(`reconciliations/paginate/${type}/${month}`, options)
      .then(response => {
        // Parse & cache data
        const data = ReconciliationGroup.toObjectList(response.data.result.reconciliations)

        return {
          records: response.data.result.records,
          data,
        }
      })
      .catch(e => {
        console.log(e)
        return {
          records: 0,
          data: [],
        }
      })
  }

  public static toObject(source: any): ReconciliationGroup {
    let instance = new ReconciliationGroup()
    Object.assign(instance, source)

    if (source.items) {
      instance.items = Reconciliation.toObjectList(source.items)
    }

    return instance
  }

  public toObject(source: any): ReconciliationGroup {
    return ReconciliationGroup.toObject(source)
  }

  public static toObjectList(source: any): ReconciliationGroup[] {
    return source.map((item: any) => ReconciliationGroup.toObject(item))
  }

  public toObjectList(source: any): ReconciliationGroup[] {
    return ReconciliationGroup.toObjectList(source)
  }
}
