import SystemtModule from '@/store/SystemModule'
import { getModule } from 'vuex-module-decorators'
import Api from './Api'
import Model from './interface/Model'
import WebMessage from './WebMessage'

export default class ReconciliationMonthly extends Model {
  protected api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'reconciliation' as string | null,
      plural: 'reconciliations' as string | null,
    },
  }

  public period: string = ''

  public client_impressions: number = 0

  public publisher_impressions: number = 0

  public orphan_impressions: number = 0

  public revenues: number = 0

  public cost: number = 0

  public status: string = ''

  public status_details: any = {}

  public get statusName(): string {
    return this.status.replaceAll('_', ' ')
  }

  public get statusColor(): string {
    if (this.status === 'pending') {
      return 'danger'
    }

    if (this.status === 'invoice_sent') {
      return 'success'
    }

    return 'warning'
  }

  public resync() {
    return ReconciliationMonthly.resync(this.period)
  }

  public static resync(period: string) {
    const api = new Api()

    return api
      .post(`reconciliation/${period}/resync`)
      .then(() =>
        WebMessage.success(
          'Reconciliation resync queued! We will let you know once the process is complete',
        ))
  }

  public export(query: any = null) {
    return ReconciliationMonthly.export([this.period], query)
  }

  public static export(periods: string[], query: any = null, mode = 'period') {
    const instance_id = getModule(SystemtModule)._uuid
    const api = new Api()

    return api
      .get('reconciliation/export', {
        periods,
        instance_id,
        query,
        mode,
      })
      .then(() =>
        WebMessage.success(
          'Generating reconciliation export file, you will be notified once the process is complete',
        ))
  }

  public overview() {
    return ReconciliationMonthly.overview(this.period)
  }

  public static overview(period: string) {
    const api = new Api()

    return api
      .get(`reconciliation/${period}/overview`)
      .then((response: any) => response.data.result.overview)
  }

  public import(file: FileList, data: string[][] = []) {
    return ReconciliationMonthly.import(this.period, file, data)
  }

  public static import(period: string, files: FileList | null, data: string[][] = []) {
    const api = new Api()

    if (!files) {
      return api
        .post(`reconciliation/${period}/import`, { data })
        .then(() => WebMessage.success('Import Complete, please review the results'))
    }

    const formData = new FormData()
    for (let i = 0; i < files.length; i++) {
      formData.append('import_files[]', files[i])
    }
    return api
      .form(`reconciliation/${period}/import`, formData)
      .then(() => WebMessage.success('Import Complete, please review the results'))
  }

  public batch(type: string, query: string[], data: any) {
    return ReconciliationMonthly.batch(this.period, type, query, data)
  }

  public static batch(period: string, type: string, query: string[], data: any) {
    const api = new Api()

    return api
      .post(`reconciliation/${period}/batch`, { data, query, type })
      .then(() => WebMessage.success('Batch Update Complete, please review the results'))
  }

  public toObject(source: any) {
    let instance = this.clone()
    Object.assign(instance, source)
    instance.id = source.period

    return instance
  }

  /**
   * Fetch records:
   * ReconciliationMonthly.paginate({
      page_size: 25,
      page: 1,
      order_by: 'period',
      order: 'desc',
      query: [],
    }).then(r => console.log(r))
   */
}
