export default [
  {
    key: 'checkbox',
    label: '',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    type: 'checkbox',
  },
  {
    key: 'code',
    label: '#',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'name',
    label: 'Item',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'start_at',
    label: 'Flight Dates',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'billing_month',
    label: 'Billing Month',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'billing_source',
    label: 'Billing Source',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'billing_mode',
    label: 'Billing Mode',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },

  {
    key: 'booked_impressions',
    label: 'Booked Impressions',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'month_impressions',
    label: 'Month Impressions',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'reconciliated_impressions',
    label: '3rd Party Impressions',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'impressions_discrepancy',
    label: 'Discrepancy',
    sortable: true,
    show: true,
    type: 'number',
    class: 'text-center align-middle',
  },

  {
    key: 'revenue_rate',
    label: 'Rate',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'revenue_total',
    label: 'Revenue total',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'cost_rate',
    label: 'Cost rate',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'cost_total',
    label: 'Cost total',
    sortable: true,
    show: false,
    class: 'text-center align-middle',
  },
  {
    key: 'net',
    label: 'Net',
    sortable: true,
    show: false,
    class: 'text-center align-middle',
  },
  {
    key: 'margin',
    label: 'Margin',
    sortable: true,
    show: false,
    class: 'text-center align-middle',
  },
  {
    key: 'status',
    label: 'status',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'action',
    label: 'Action',
    sortable: false,
    show: true,
    type: 'actions',
    class: 'text-center align-middle',
  },
]
