





































































































































































































































































































































































































































































































































































































































































































































import {
  Vue, Component, Prop, Watch, Ref,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import MediaPlan from '@/models/MediaPlan'
import {
  ImpressionModelOptions,
  OrderTypeOptions,
  CreativeLength,
  DaysOfWeek,
  OrderTypes,
  RedistributeImpressions,
} from '@/models/interface/Common'
import { integerMask, currencyMask } from '@/models/interface/Masks'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import SelectOption from '@/models/interface/SelectOption'
import IconAction from '@/components/IconAction/IconAction.vue'
import { clone as _clone } from 'lodash'
import moment from 'moment'
import MediaPackagePicker from '@/components/MediaPackagePicker/MediaPackagePicker.vue'
import MediaPlanItem from '@/models/MediaPlanItem'
import dmas from '@/data/dmas'
import MaxAvails from '@/models/MaxAvails'
// @ts-ignore
import LiquorTree from 'liquor-tree'
import state_options from '@/data/state_options'
import WebMessage from '@/models/WebMessage'
import BigNumber from 'bignumber.js'
import DynamicRatePicker from '@/components/DynamicRatePicker/DynamicRatePicker.vue'
import fields from './fields'

Vue.use(LiquorTree)

@Component({
  components: {
    FormInput,
    SelectPicker,
    DatePicker,
    IconAction,
    MediaPackagePicker,
    DynamicRatePicker,
  },
})
export default class MediaPlanBuilder extends ViewModel {
  @Ref() readonly includeTree: any

  @Ref() readonly excludeTree: any

  @Prop({ required: true })
  private value!: MediaPlan

  private target: MediaPlanItem | null = null

  private target_index: number = 0

  private delete_item_acknowledged: boolean = false

  private local_dynamic_rate_id: null | string = null

  private modal = {
    delete: false,
    targetting: false,
  }

  private targetting = {
    include: [] as string[],
    exclude: [] as string[],
  }

  private apply_to_all: boolean = false

  private redistribute_impressions: string = 'no'

  private include_filter: string = ''

  private exclude_filter: string = ''

  private include_zipcodes: string = ''

  private exclude_zipcodes: string = ''

  private max_avails_percentage = 10

  public tree_options = {
    checkbox: true,
    checkOnSelect: true,
    autoCheckChildren: false,
  }

  private loading_max_avails: boolean = false

  private max_avails: { [key: number]: { monthly_avails: number } } = {}

  private grps: number[] = []

  public get options() {
    return {
      redistribute: RedistributeImpressions,
      order_type: OrderTypeOptions,
      impression_model: ImpressionModelOptions,
      demo_targets: this.localValue.metadata.demo_targets.map(
        (d: any, index: number) => new SelectOption(`${d.target}${d.age_low}${d.age_high}`, index),
      ),
      creative_length: CreativeLength,
      daysOfWeek: DaysOfWeek,
      orderTypes: OrderTypes,
      spots: [
        new SelectOption('0', 0),
        new SelectOption('1', 1),
        new SelectOption('2', 2),
        new SelectOption('3', 3),
        new SelectOption('4', 4),
        new SelectOption('5', 5),
      ],
      tree: [
        {
          text: 'DMAs',
          id: null,
          state: { selectable: false },
          children: dmas
            .map(d => ({
              text: `${d.name} (${d.id})`,
              type: 'dma',
              id: d.id,
              state: { selectable: true },
            }))
            .sort((a, b) => a.text.localeCompare(b.text)),
        },
        {
          text: 'States',
          id: null,
          state: { selectable: false },
          children: state_options
            .map(d => ({
              text: `${d.name} (${d.value})`,
              type: 'state',
              id: d.value,
              state: { selectable: true },
            }))
            .sort((a, b) => a.text.localeCompare(b.text)),
        },
      ],
    }
  }

  private get masks() {
    return {
      integerMask,
      currencyMask,
    }
  }

  protected get fields() {
    return fields.filter(
      (field: any) => field.visible === undefined || field.visible(this.localValue),
    )
  }

  private get localValue() {
    return this.value
  }

  private set localValue(value: any) {
    this.$emit('input', value)
  }

  private changeSpot(action: string, line_item: number, spot: number) {
    let values = _clone(this.localValue.line_items[line_item].metadata.spots)

    if (action === 'add') {
      values[spot]++
    } else if (values[spot] > 0) {
      values[spot]--
    }

    this.localValue.line_items[line_item].metadata.spots = values
  }

  private getWeekDates(start_at: string, week: number): string {
    const start = moment(start_at).add(week, 'weeks')
    return `${start.format('MM/DD/YYYY')} ~ ${start
      .endOf('week')
      .add(1, 'days')
      .format('MM/DD/YYYY')}`
  }

  public editTargetting(index: number) {
    this.target_index = index
    this.apply_to_all = false
    this.target = this.localValue.line_items[this.target_index]
    this.modal.targetting = true
    setTimeout(() => {
      this.includeTree.findAll().uncheck()
      this.excludeTree.findAll().uncheck()
      this.includeTree
        .findAll(
          (n: any) =>
            this.target?.metadata.targetting.include.dmas.includes(n.id)
            || this.target?.metadata.targetting.include.states.includes(n.id),
        )
        .check()
      this.excludeTree
        .findAll(
          (n: any) =>
            this.target?.metadata.targetting.exclude.dmas.includes(n.id)
            || this.target?.metadata.targetting.exclude.states.includes(n.id),
        )
        .check()

      this.include_zipcodes = this.target?.metadata.targetting.include.zipcodes.join(', ')
      this.exclude_zipcodes = this.target?.metadata.targetting.exclude.zipcodes.join(', ')
    }, 500)
  }

  public targettingConfirm() {
    if (!this.target) {
      return
    }

    let include_zipcodes = this.include_zipcodes.match(/(\d{5})/gim)
    let exclude_zipcodes = this.exclude_zipcodes.match(/(\d{5})/gim)

    if (include_zipcodes && exclude_zipcodes) {
      exclude_zipcodes = exclude_zipcodes.filter(
        zip => !include_zipcodes || !include_zipcodes.includes(zip),
      )
    }

    let include_dmas = this.includeTree
      .checked()
      .filter((o: any) => o.parent && o.parent.text === 'DMAs')
      .map((o: any) => o.id)
    let include_states = this.includeTree
      .checked()
      .filter((o: any) => o.parent && o.parent.text === 'States')
      .map((o: any) => o.id)
    let exclude_dmas = this.excludeTree
      .checked()
      .filter((o: any) => o.parent && o.parent.text === 'DMAs' && !include_dmas.includes(o.id))
      .map((o: any) => o.id)
    let exclude_states = this.excludeTree
      .checked()
      .filter((o: any) => o.parent && o.parent.text === 'States' && !include_states.includes(o.id))
      .map((o: any) => o.id)

    let value = {
      include: {
        dmas: include_dmas,
        states: include_states,
        zipcodes: include_zipcodes ? [...new Set(include_zipcodes)] : [],
      },
      exclude: {
        dmas: exclude_dmas,
        states: exclude_states,
        zipcodes: exclude_zipcodes ? [...new Set(exclude_zipcodes)] : [],
      },
    }

    if (this.apply_to_all) {
      this.localValue.line_items.forEach((line_item: MediaPlanItem) => {
        line_item.metadata.targetting = value
      })
    } else {
      this.localValue.line_items[this.target_index].metadata.targetting = _clone(value)
    }
    this.updateMaxAvails()
  }

  private getTargeting(line_item: MediaPlanItem, type: string) {
    const include = line_item.metadata.targetting.include[type]
    const exclude = line_item.metadata.targetting.exclude[type]
    const limit = 5

    let ret = ''

    if (include.length + exclude.length === 0) {
      ret = '-'
    } else if (include.length + exclude.length <= limit) {
      if (include.length > 0) {
        ret += include
          .map((i: any) => {
            if (type === 'dmas') {
              return `<span class="text-success"><b>+</b> ${
                dmas.find(d => d.id === i)?.name
              } (${i})</span>`
            }
            return `<span class="text-success"><b>+</b> ${i}</span>`
          })
          .join(', ')
      }

      if (ret && exclude.length > 0) ret += ', '
      if (exclude.length > 0) {
        ret += exclude
          .map((i: any) => {
            if (type === 'dmas') {
              return `<span class="text-danger"><b>-</b> ${
                dmas.find(d => d.id === i)?.name
              } (${i})</span>`
            }
            return `<span class="text-danger"><b>-</b> ${i}</span>`
          })
          .join(', ')
      }
    } else {
      if (include.length > 0) ret += `${include.length} included`

      if (ret && exclude.length > 0) ret += ', '

      if (exclude.length > 0) ret += `${exclude.length} excluded`
    }

    if (ret.slice(-2) === ', ') ret = ret.slice(0, -2)

    return ret
  }

  private updateMaxAvails() {
    this.loading_max_avails = true
    MaxAvails.getMaxAvails(
      this.localValue.line_items.map((item: MediaPlanItem) => ({
        period: item.period,
        targetting: item.metadata.targetting,
        demo: this.localValue.metadata.demo_targets[item.metadata.demo_target],
      })),
    )
      .then(response => {
        this.loading_max_avails = false
        this.max_avails = response.data.result.max_avails.map((value: any, index: number) => {
          let flight_time = moment(this.localValue.line_items[index].end_at).diff(
            moment(this.localValue.line_items[index].start_at),
            'days',
          ) + 1
          return (value.monthly_avails / 30) * flight_time
        })
        this.grps = response.data.result.grps
      })
      .catch(error => {
        this.loading_max_avails = false
        this.max_avails = []
        this.grps = []
      })
  }

  private watchDateChange() {
    this.localValue.line_items.forEach((item: MediaPlanItem) => {
      if (!item.has_listener) {
        item.has_listener = true
        this.$watch(
          () => item.metadata.demo_target,
          () => {
            this.updateMaxAvails()
          },
        )
        this.$watch(
          () => item.start_at,
          () => {
            this.updateMaxAvails()
          },
        )
        this.$watch(
          () => item.end_at,
          () => {
            this.updateMaxAvails()
          },
        )
        this.$watch(
          () => item.total_spots,
          (value, old) => {
            if (value == 0) value = 1
            if (old == 0) old = 1
            item.formImpressions = value * (item.formImpressions / old)
          },
        )
      }
    })
  }

  public clearTargetting(target: string, type: string) {
    if (target === 'tree') {
      if (type === 'include') {
        this.includeTree.checked().uncheck()
      } else {
        this.excludeTree.checked().uncheck()
      }
    } else if (type === 'include') {
      this.include_zipcodes = ''
    } else {
      this.exclude_zipcodes = ''
    }
  }

  public clone(value: MediaPlanItem) {
    value = MediaPlanItem.toObject({ ...value })
    value.number = this.localValue.line_items.length + 1
    value.visible = true
    value._showDetails = true
    value.has_listener = false
    value.id = ''
    this.localValue.line_items.push(value)
    this.watchDateChange()
  }

  @Watch('localValue.line_items')
  public onChangeLineItems() {
    this.watchDateChange()
    this.updateMaxAvails()
  }

  public remove(value: MediaPlanItem) {
    this.delete_item_acknowledged = false
    this.target = value
    this.redistribute_impressions = 'no'
    this.modal.delete = true
  }

  public removeConfirm() {
    if (this.target) {
      if (this.redistribute_impressions !== 'no') {
        let elegible_items = this.localValue.line_items.filter(
          (item: MediaPlanItem) =>
            this.target
            && item.number !== this.target.number
            && (this.redistribute_impressions === 'all'
              || this.redistribute_impressions === item.metadata.order_type),
        )
        let total_impressions = elegible_items.reduce(
          (carry: BigNumber, item: MediaPlanItem) => carry.plus(item.impressions),
          new BigNumber(0),
        )
        let impressions = this.target.impressions
        elegible_items.forEach((item: MediaPlanItem) => {
          item.formImpressions += +item.bnImpressions
            .div(total_impressions)
            .times(impressions)
            .integerValue()
        })
      }
      this.localValue.line_items.splice(this.localValue.line_items.indexOf(this.target), 1)
      this.resetNumbers()
    }
  }

  public moveUp(value: MediaPlanItem) {
    const index = this.localValue.line_items.indexOf(value)
    if (index > 0) {
      this.localValue.line_items.splice(
        index - 1,
        0,
        this.localValue.line_items.splice(index, 1)[0],
      )
      this.resetNumbers()
    }
  }

  public moveDown(value: MediaPlanItem) {
    const index = this.localValue.line_items.indexOf(value)
    if (index < this.localValue.line_items.length - 1) {
      this.localValue.line_items.splice(
        index + 1,
        0,
        this.localValue.line_items.splice(index, 1)[0],
      )
      this.resetNumbers()
    }
  }

  public resetNumbers() {
    let count = 1
    this.localValue.line_items.forEach((i: MediaPlanItem) => {
      i.number = count++
    })
  }

  private lineItemSpotIndex(line_item: MediaPlanItem, index: number): number {
    let pointer = moment(this.localValue.start_at)
      .startOf('week')
      .add(index, 'weeks')
      .add(1, 'day')
    let start = moment(line_item.start_at)
      .startOf('week')
      .add(1, 'day')

    if (index > 0) {
      start
        .add(index, 'weeks')
        .startOf('week')
        .add(1, 'day')
    }

    let ret: number = 1 + index

    while (pointer.isBefore(start)) {
      pointer.add(1, 'weeks')
      ret++
    }

    return ret
  }

  public created() {
    this.watchDateChange()
    this.updateMaxAvails()
  }
}
