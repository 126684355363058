





















import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class DropArea extends Vue {
  private show_drop_overlay: boolean = false

  public files = []

  private events = ['dragover', 'dragleave', 'drop', 'dragdrop']

  private onDragEnter(e: any) {
    e.preventDefault()
    this.show_drop_overlay = true
  }

  private onDragLeave() {
    this.show_drop_overlay = false
  }

  private onDrop(e: any) {
    e.preventDefault()
    this.show_drop_overlay = false
    this.$emit('file-drop', e.dataTransfer.files)
  }

  private preventDefaults(e: any) {
    e.preventDefault()
  }

  public mounted() {
    document.ondragenter = this.onDragEnter
    this.events.forEach(eventName => {
      document.addEventListener(eventName, this.preventDefaults)
    })
  }

  public beforeDestroy() {
    document.ondragenter = null
    this.events.forEach(eventName => {
      document.removeEventListener(eventName, this.preventDefaults)
    })
  }
}
