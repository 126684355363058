






































import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator'
import IconAction from '@/components/IconAction/IconAction.vue'
import { cdnPath } from '@/models/interface/Common'

@Component({
  components: {
    IconAction,
  },
})
export default class FilePicker extends Vue {
  @Prop({ default: '' })
  public name!: string

  @Prop({ default: '' })
  public label!: string

  @Prop({ default: null, required: true })
  private value!: string | null

  @Prop({ default: null })
  private file!: any

  @Prop({ default: 'image' })
  private type!: string

  @Prop({ default: '' })
  private palceholder!: string

  @Prop({ default: '' })
  private description!: string

  @Prop({ default: false })
  public vertical!: boolean

  private image_preview: any = null

  @Prop({ default: '' })
  public rules!: [Object, String]

  public get formLabel() {
    return this.label + (this.rules.includes('required') ? ' *' : '')
  }

  public get local_value(): string | null {
    return this.value
  }

  public set local_value(val: string | null) {
    this.$emit('input', val)
  }

  public get local_file(): any {
    return this.file
  }

  public set local_file(val: any) {
    this.$emit('update:file', val)
  }

  private get accept() {
    if (this.type === 'image') {
      return '.jpg, .png, .gif, .jpeg, .svg'
    }
    if (this.type === 'video') {
      return '.mp4, .mov, .avi, .mkv, .wmv, .flv, .mpg, .mpeg'
    }
    return ''
  }

  private get cdnPath() {
    return cdnPath
  }

  @Watch('local_file')
  public onChangeFile() {
    if (this.local_file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        this.image_preview = reader.result
      }
      reader.readAsDataURL(this.local_file)
    } else if (this.image_preview) {
      this.image_preview = null
    }
  }

  public deleteFile() {
    if (this.local_file) {
      this.local_file = null
      this.image_preview = null
    } else {
      this.local_value = null
    }
  }

  public created() {
    this.local_value = this.value
    this.local_file = this.file
  }
}
