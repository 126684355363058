import Inventory from './Inventory'
import Model from './interface/Model'

export default class MediaPackage extends Model {
  protected api_settings = {
    save_mode: 'form',
    paths: {
      singular: 'media_package' as string | null,
      plural: 'media_packages' as string | null,
    },
  }

  public name: string = ''

  public program_name: string = ''

  public status: string = 'active'

  public description: string = ''

  public image: string | null = null

  public file: any = null

  public overwrite_rate: boolean = false

  public rate: number = 45

  public adunit_ids: string[] = []

  public get adunits(): Array<Inventory> {
    return Inventory.filter(this.adunit_ids)
  }

  public set adunits(adunits: Array<Inventory>) {
    Inventory.toObjectList(adunits)
  }

  /**
   * UI Form Fields
   */
  public get formName(): string {
    return this.name !== '' ? this.name : 'Untitled Media Package'
  }

  constructor(source: any = {}) {
    super()

    Object.assign(this, source)

    if (source.line_items) {
      this.adunits = Inventory.toObjectList(source.adunits)
    }

    return this
  }

  public get status_color(): string {
    if (this.status === 'active') {
      return 'success'
    }
    if (this.status === 'inactive') {
      return 'danger'
    }
    return 'warning'
  }

  public deactivate() {
    this.status = 'inactive'

    return this.save()
  }

  public activate() {
    this.status = 'active'

    return this.save()
  }
}
