export default [
  {
    key: 'checkbox',
    label: '',
    sortable: false,
    class: 'align-middle',
    show: true,
    type: 'checkbox',

  },
  {
    key: 'publisher',
    label: 'publisher',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
  },

  {
    key: 'top_inventory_name',
    label: 'Inventory name',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
  },

  {
    key: 'billing_source',
    label: 'Billing source',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'impressions',
    label: 'Month impressions',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'items_reconciliated_impressions',
    label: '3rd Party Impressions',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'impressions_discrepancy',
    label: 'Discrepancy',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'cost_rate',
    label: 'Rate',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'cost_total',
    label: 'Total Cost',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'top_inventory_id',
    label: '',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
  },
  {
    key: 'status',
    label: 'Status',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'action',
    label: 'Action',
    sortable: false,
    show: true,
    type: 'actions',
    class: 'text-center align-middle',
  },
]
