














































































import { Component, Prop, Watch } from 'vue-property-decorator'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import Terms from '@/models/Terms'
import ViewModel from '@/models/ViewModel'
import SelectOption from '@/models/interface/SelectOption'
// @ts-ignore
import CKEditor from '@ckeditor/ckeditor5-vue2'
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

@Component({
  components: {
    SelectPicker,
    ckeditor: CKEditor.component,
  },
})
export default class TermsPicker extends ViewModel {
  @Prop({ default: true })
  public edit!: boolean

  @Prop({ default: false })
  public allow_empty!: boolean

  @Prop({ default: false })
  public disabled!: boolean

  @Prop({ default: false })
  public multiple!: boolean

  @Prop({ default: false })
  public create_panel!: boolean

  @Prop({ default: 'Terms' })
  public label!: string

  @Prop({ default: () => null })
  public value!: string | null

  @Prop({ default: () => null })
  public terms!: string | null

  @Prop({ default: () => null })
  public type!: string | null

  public editor = ClassicEditor

  public local_value: string[] | string | null = []

  public local_terms: string | null = ''

  public new_id: string = ''

  public show_sidebar: boolean = false

  public update: boolean = true

  public uuid: string = ''

  public busy: boolean = false

  public options: SelectOption[] = []

  public label_name: string = 'N/A'

  public ready: boolean = false

  public edit_terms: boolean = false

  public get picker_options() {
    return [
      {
        name: 'N/A',
        value: '',
      },
      {
        name: 'Custom',
        value: 'custom',
      },
      ...this.options,
    ]
  }

  public mounted() {
    this.uuid = this.randomUUID()
  }

  public created() {
    if (this.edit) {
      if (!this.value || (Array.isArray(this.value) && this.value.length == 0)) {
        this.ready = true
      } else {
        this.busy = true
        Terms.module
          .searchOptions({
            value: this.value,
          })
          .then(response => {
            this.options = response
            this.busy = false
            this.local_value = this.value
            this.ready = true
          })
      }
    } else {
      this.updateLabel()
    }
  }

  public open() {
    if (this.options.length == 0) this.searchOptions()
  }

  public updateLabel() {
    if (!this.value) {
      this.label_name = 'N/A'
    } else if (this.value === 'custom') {
      this.label_name = 'Custom'
    } else if (!Array.isArray(this.value)) {
      Terms.module
        .searchOptions({
          value: this.value,
        })
        .then(response => {
          this.label_name = response[0].name
          this.ready = true
        })
    }
  }

  public searchOptions(search = '*') {
    this.busy = true
    if (!search) search = '*'

    Terms.module
      .searchOptions({
        search: search.includes('*') ? search : `*${search}*`,
        type: this.type,
      })
      .then(response => {
        this.options = response
        this.busy = false
        this.ready = true
      })
  }

  @Watch('local_value')
  public onChangeValue(val: any) {
    this.update = false
    if (val == null) {
      this.$emit('input', null)
    } else {
      this.$emit('input', this.local_value)
    }
  }

  @Watch('value')
  public onChangeProp(val: any) {
    if (this.update) {
      if (val == null) {
        this.local_value = null
      } else {
        this.local_value = this.value
      }
    }
    this.update = true
    this.updateLabel()
  }

  @Watch('terms')
  public onChangeTerms(val: any) {
    this.local_terms = this.terms
  }

  public cancelTerms() {
    this.edit_terms = false
    this.local_terms = this.terms
  }

  public updateTerms() {
    this.edit_terms = false
    this.$emit('update:terms', this.local_terms)
  }
}
