import { integerMask, currencyMask } from '@/models/interface/Masks'
import MediaPlan from '@/models/MediaPlan'

export default [
  {
    key: 'number',
    label: '#',
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
    thStyle: { width: '2rem' },
  },
  {
    key: 'name',
    label: 'Name',
    allow_edit: true,
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
  },
  {
    key: 'formStartAt',
    label: 'Start',
    type: 'date',
    allow_edit: true,
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
    thStyle: { width: '10rem' },
  },
  {
    key: 'formEndAt',
    label: 'End',
    type: 'date',
    allow_edit: true,
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
    thStyle: { width: '10rem' },
  },
  {
    key: 'formImpressions',
    label: 'Impressions',
    type: 'number',
    allow_edit: true,
    visible: (media_plan: MediaPlan) => !media_plan.isLinear,
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
    thStyle: { width: '10rem' },
  },
  {
    key: 'formSpotImpressions',
    label: 'Spot Impressions',
    type: 'number',
    allow_edit: true,
    visible: (media_plan: MediaPlan) => media_plan.isLinear,
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
    thStyle: { width: '10rem' },
  },
  {
    key: 'formGrossRate',
    label: 'Gross Rate',
    type: 'number',
    // mask: currencyMask,
    // prepend: '$',
    label_format: 'currency',
    allow_edit: (media_plan: MediaPlan) => media_plan.isLinear,
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
    thStyle: { width: '10rem' },
  },
  {
    key: 'formNetRate',
    label: 'Net Rate',
    type: 'number',
    // mask: currencyMask,
    // prepend: '$',
    label_format: 'currency',
    allow_edit: (media_plan: MediaPlan) => !media_plan.isLinear,
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
    thStyle: { width: '10rem' },
  },
  {
    key: 'formGrossCost',
    label: 'Total Gross Cost',
    type: 'number',
    allow_edit: (media_plan: MediaPlan) => media_plan.isLinear,
    // mask: currencyMask,
    // prepend: '$',
    label_format: 'currency',
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
    thStyle: { width: '10rem' },
  },
  {
    key: 'formNetCost',
    label: 'Total Net Cost',
    type: 'number',
    // mask: currencyMask,
    // prepend: '$',
    label_format: 'currency',
    allow_edit: (media_plan: MediaPlan) => !media_plan.isLinear,
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
    thStyle: { width: '10rem' },
  },
  {
    key: 'action',
    label: 'Actions',
    type: 'action',
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
    thStyle: { width: '5rem' },
  },
]
